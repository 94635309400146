import axios from 'axios'
import Vue from 'vue'
import Router from '../router'
import { Toast } from 'vant'
import { removeCookie } from '@/util/util'
// import crypto from '../'

Vue.use(Toast)
// import { getCookie } from '@/util/util.js'
import store from '@/store'
import crypto from './crypto'

const service = axios.create({
  baseURL: process.env.VUE_APP_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
    '_o': '0',
  },
  timeout: 0, // request timeout
})
service.interceptors.request.use(
  (config) => {
    // console.log('interceptors.request' + config.QZ);
    if (config.domain == 'EMOTION') {
      config.baseURL = process.env.VUE_APP_API_TOPIA
      config.headers['token'] = sessionStorage.getItem('token')
    } else if (config.QZ) {
      // console.log(config.QZ);
      if ('QZD' == config.QZ) {
        config.baseURL = process.env.VUE_APP_API_QZ_D // 雀诊医护端医生服务接口
        config.headers['_o'] = '1'
        config.headers['X-inner'] = '1'
        // config.headers['qz'] = 'QZD'
      } else if ('QZN' == config.QZ) {
        config.baseURL = process.env.VUE_APP_API_QZ_N // 雀诊医护端护士服务接口
        config.headers['_o'] = '1'
      } else {
        // console.log('QZ');
        config.baseURL = process.env.VUE_APP_API_QZ // 雀诊患者端接口
        config.headers['_o'] = '0'
        config.headers['Authorization'] = sessionStorage.getItem('Authorization')
        // config.headers['qz'] = 'QZ'
      }
      if (sessionStorage.getItem('qztoken')) {
        config.headers['token'] = sessionStorage.getItem('qztoken')
      }
    } else {
      config.headers['_o'] = '0'
      if (sessionStorage.getItem('token')) {
        config.headers['token'] = sessionStorage.getItem('token')
      }
    }
    // console.log(config);
    // do something before request is sent
    //config.headers['token'] = '1'
    return config
  },
  (error) => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  (response) => {
    // console.log(response);
    const qz = response.config.QZ
    const domain = response.config.domain
    if (domain == 'EMOTION') {
      // 情感对话接口
      var res = response.data
      if (res.code == '200') {
        return Promise.resolve(res.result)
      } else {
        return Promise.reject(res.message || 'Error')
      }
    } else if (qz) {
      // 雀诊的接口
      var res = response.data
      // console.log(res.code);
      if (res.code != 0) {
        // console.log(res)
        if (res.msg != null && res.msg.length > 0) {
          Toast.fail(res.msg)
        } else {
          Toast.fail('服务出错，请稍后重试')
        }
        return Promise.reject(res.msg || 'Error')
      } else {
        return Promise.resolve(res.data)
      }
    } else {
      // external的接口
      var res = response.data
      // console.log(response);
      if (res.code != 200) {
        // console.log(response.config);
        if (res.code == 307) {
          Toast.fail('登录失效，请重新登录')
          sessionStorage.removeItem("token")
          //Router.push('/tkarchives')
        } else {
          // console.log(res)
          if (res.message != null && res.message.length > 0) {
            Toast.fail(res.message)
          } else {
            Toast.fail('服务出错，请稍后重试')
          }

          return Promise.reject(res.message || 'Error')
        }
      } else {
        // healthRecordApi类接口需要解密
        // console.log(process.env.VUE_APP_CRYPTO);
        if (1 == process.env.VUE_APP_CRYPTO) {
          if (response.config.url.includes('healthRecordApi') || response.config.url.includes('getSigned')) {
            // 进行解密 

            var result = res.result
            // console.log('result', result);
            if (result == null || result.length == 0) {
              return Promise.resolve(res.result)
            }
            // console.log(crypto.decryptData(result));
            res.result = crypto.decryptData(result)
          }
        }
        return Promise.resolve(res.result)
      }
    }
  },
  (error) => {
    console.log('err' + error) // for debug
    // Toast.fail(res.message || error || '请求失败，请稍后重试')
    Toast.fail('网络出错, 请稍后重试')
    return Promise.reject(error)
  }
)

export default service