// 智能营销Router
// Created by guoyijie on 2022-12-13.


const intelligentMarketingRouter = [{
  path: '/caseUpload',
  name: 'caseUpload',
  meta: {
    title: "我的预核保"
  },
  component: () =>
    import('@/views/intelligentMarketing/home/index'),
},
{
  path: '/caseList',
  name: 'caseList',
  meta: {
    title: "我的预核保"
  },
  component: () =>
    import('@/views/intelligentMarketing/home/caseList'),
},
{
  path: '/previewPDF',
  name: 'previewPDF',
  meta: {
    title: "我的预核保"
  },
  component: () =>
    import('@/views/intelligentMarketing/home/previewPDF'),
},
{
  path: '/caseDetails',
  name: 'caseDetails',
  meta: {
    title: "我的预核保"
  },
  component: () =>
    import('@/views/intelligentMarketing/home/caseDetails'),
},
{
  path: '/caseImgs',
  name: 'caseImgs',
  meta: {
    title: "我的预核保"
  },
  component: () =>
    import('@/views/intelligentMarketing/home/caseImgs'),
},
{
  path: '/login',
  name: 'login',
  meta: {
    title: "登录"
  },
  component: () =>
    import('@/views/intelligentMarketing/login/index'),
},
{
  path: '/service',
  name: 'service',
  meta: {
    title: "我的客服"
  },
  component: () =>
    import('@/views/intelligentMarketing/service/index'),
},
// {
//     path: '/aaa',
//     name: 'service',
//     meta: {
//         title: "疾病方案"
//     },
//     component: () =>
//         import('@/views/intelligentMarketing/service/diseaseKnowledge'),
// },
{
  path: '/diseaseKnowledge',
  name: 'disease-knowledge',
  meta: {
    title: "疾病方案"
  },
  component: () =>
    import('@/views/intelligentMarketing/service/diseaseKnowledge1'),
},
{
  path: '/userAgreement',
  name: 'userAgreement',
  meta: {
    title: "用户协议"
  },
  component: () =>
    import('@/views/intelligentMarketing/agreement/userAgreement'),
},
{
  path: '/privacyAgreement',
  name: 'privacyAgreement',
  meta: {
    title: "隐私协议"
  },
  component: () =>
    import('@/views/intelligentMarketing/agreement/privacyAgreement'),
},
{
  path: '/intelligentChat',
  name: 'intelligentChat',
  meta: {
    title: "智能助理"
  },
  component: () =>
    import('@/views/intelligentMarketing/chat/index'),
},
// 科普对话的正常页面
{
  path: '/diseaseqa',
  name: 'diseaseqa',
  meta: {
    // TODO-T 临时修改
    // title: "不良反应评估"
    // --------------
    // TODO-T 正常代码
    title: "科普对话"
    // ----------
  },
  component: () =>
    import('@/views/diseaseQA/index'),
},


// {
//     path: '/diseaseqademo',
//     name: 'diseaseqademo',
//     meta: {
//         title: "乳腺癌原模型测试"
//     },
//     component: () =>
//         import('@/views/diseaseQA/index-test'),
// },
// {
//     path: '/index',
//     name: 'chat',
//     meta: {
//         title: "智能助理"
//     },
//     component: () =>
//         import ('@/views/intelligentMarketing/chat/index'),
// },
{
  path: '/chatDetails',
  name: 'chatDetails',
  meta: {
    title: "智能助理"
  },
  component: () =>
    import('@/views/intelligentMarketing/chat/chat-details'),
},
{
  path: '/chatUpload',
  name: 'chatUpload',
  meta: {
    title: "上传报告"
  },
  component: () =>
    import('@/views/intelligentMarketing/chat/chat-upload'),
},
{
  path: '/disease',
  name: 'disease',
  meta: {
    title: "疾病管理方案"
  },
  component: () =>
    import('@/views/intelligentMarketing/programme/disease'),
},
{
  path: '/programme',
  name: 'programme',
  meta: {
    title: "医疗费用保障方案"
  },
  component: () =>
    import('@/views/intelligentMarketing/programme/programme'),
},
{
  path: '/relevantCases',
  name: 'relevantCases',
  meta: {
    title: "相关案例"
  },
  component: () =>
    import('@/views/intelligentMarketing/programme/relevantCases'),
},
{
  path: '/tubercle',
  name: 'tubercle',
  meta: {
    title: "相关案例"
  },
  component: () =>
    import('@/views/intelligentMarketing/programme/tubercle'),
},
{
  path: '/QRcode',
  name: 'QRcode',
  meta: {
    title: "联系我们"
  },
  component: () =>
    import('@/views/intelligentMarketing/programme/QRcode'),
},
{
  path: '/foundation',
  name: 'foundation',
  meta: {
    title: "慈善基金"
  },
  component: () =>
    import('@/views/intelligentMarketing/foundation/FoundationListPage'),
},
{
  path: '/clinicaltriallist',
  name: 'clinical-trial-list',
  meta: {
    title: "免费入组临床试验"
  },
  component: () =>
    import('@/views/intelligentMarketing/clinicalTrial/ClinicalTrialListPage'),
},
{
  path: '/clinicaltrialdetail',
  name: 'clinical-trial-detail',
  meta: {
    title: "免费入组临床试验"
  },
  component: () =>
    import('@/views/intelligentMarketing/clinicalTrial/ClinicalTrialDetailPage'),
},
]

export default intelligentMarketingRouter